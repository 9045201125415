import { FILE_TYPES_BY_KEY } from "./FileTypes";

export const DIMENSIONAL_METADATA_KEYS = {
  x: "ideas.spacingInfo.numPixels.x" as const,
  y: "ideas.spacingInfo.numPixels.y" as const,
  z: "ideas.spacingInfo.numPixels.z" as const,
};

export const metadataMap: {
  [value in keyof typeof FILE_TYPES_BY_KEY]: Record<string, string> | undefined;
} = {
  cell_set: {
    "isx.extraProperties.idps.cellset.method": "Cell Extraction Method",
    "ideas.metrics.num_accepted_cells": "Number of accepted cells",
    "ideas.metrics.num_rejected_cells": "Number of rejected cells",
    "ideas.metrics.num_undecided_cells": "Number of undecided cells",
    "ideas.metrics.total_num_cells": "Total number of cells",
    "ideas.timingInfo.numTimes": "Number of timepoints",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Sampling Rate (Hz)",
    "isx.extraProperties.processingInterface.recordingUUID":
      "Acquisition Recording ID",
    "isx.extraProperties.idps.efocus": "E-focus",
    "isx.extraProperties.idps.channel": "Channel",
    "isx.extraProperties.microscope.widefield": "Widefield",
    "ideas.Cell Extraction Method": "Cell Extraction Method",
    "ideas.Number of Frames": "Number of Frames",
    "ideas.Sampling Rate (Hz)": "Sampling Rate (Hz)",
    "ideas.Recording Duration (s)": "Recording Duration (s)",
    "ideas.Number of Extracted ROIs": "Number of Extracted ROIs",
    "ideas.Number of Accepted ROIs": "Number of Accepted ROIs",
    "ideas.Number of Rejected ROIs": "Number of Rejected ROIs",
    "ideas.Average trace amplitude": "Average trace amplitude",
  } as const,
  movie: {
    "isx.extraProperties.microscope.type": "Miniscope",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Frame Rate (Hz)",
    "isx.cameraName": "Camera name",
    "ideas.timingInfo.numTimes": "Number of frames",
    "ideas.timingInfo.blank": "Number of blank frames",
    "ideas.timingInfo.cropped": "Number of cropped frames",
    "ideas.timingInfo.dropped": "Number of dropped frames",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "nvision.droppedFrames": "Dropped frames",
    [DIMENSIONAL_METADATA_KEYS.x]: "Width (pixels)",
    [DIMENSIONAL_METADATA_KEYS.y]: "Height (pixels)",
    "isx.extraProperties.idps.efocus": "E-focus",
    "isx.extraProperties.probe.name": "Probe",
    "isx.extraProperties.microscope.serial": "Miniscope Serial Number",
    "isx.extraProperties.processingInterface.recordingUUID":
      "Acquisition Recording ID",
    "dlc.model_neural_net_type": "Model neural network type",
    "dlc.body_parts": "Body parts",
    "ideas.dlc.task": "Model task name",
    "ideas.dlc.scorer": "Model scorer",
    "ideas.dlc.date": "Model training date",
    "ideas.dlc.multi_animal_project": "Multi-animal project",
    "ideas.dlc.model_neural_net_type": "Model neural network type",
    "ideas.dlc.body_parts": "Body parts",
    "ideas.dlc.snapshot": "Model snapshot number",
    "isx.extraProperties.idps.channel": "Channel",
  } as const,
  miniscope_movie: {
    "isx.extraProperties.microscope.type": "Miniscope",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Frame Rate (Hz)",
    "isx.cameraName": "Camera name",
    "ideas.timingInfo.numTimes": "Number of frames",
    "ideas.timingInfo.blank": "Number of blank frames",
    "ideas.timingInfo.cropped": "Number of cropped frames",
    "ideas.timingInfo.dropped": "Number of dropped frames",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "nvision.droppedFrames": "Dropped frames",
    [DIMENSIONAL_METADATA_KEYS.x]: "Width (pixels)",
    [DIMENSIONAL_METADATA_KEYS.y]: "Height (pixels)",
    "isx.extraProperties.idps.efocus": "E-focus",
    "isx.extraProperties.probe.name": "Probe",
    "isx.extraProperties.microscope.serial": "Miniscope Serial Number",
    "isx.extraProperties.processingInterface.recordingUUID":
      "Acquisition Recording ID",
    "dlc.model_neural_net_type": "Model neural network type",
    "dlc.body_parts": "Body parts",
    "isx.extraProperties.idps.channel": "Channel",
    "isx.extraProperties.microscope.widefield": "Widefield",
  } as const,
  nvision_movie: {
    "isx.extraProperties.microscope.type": "Miniscope",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Frame Rate (Hz)",
    "isx.cameraName": "Camera name",
    "ideas.timingInfo.numTimes": "Number of frames",
    "ideas.timingInfo.blank": "Number of blank frames",
    "ideas.timingInfo.cropped": "Number of cropped frames",
    "ideas.timingInfo.dropped": "Number of dropped frames",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "nvision.droppedFrames": "Dropped frames",
    [DIMENSIONAL_METADATA_KEYS.x]: "Width (pixels)",
    [DIMENSIONAL_METADATA_KEYS.y]: "Height (pixels)",
    "isx.extraProperties.idps.efocus": "E-focus",
    "isx.extraProperties.probe.name": "Probe",
    "isx.extraProperties.microscope.serial": "Miniscope Serial Number",
    "isx.extraProperties.processingInterface.recordingUUID":
      "Acquisition Recording ID",
    "dlc.model_neural_net_type": "Model neural network type",
    "dlc.body_parts": "Body parts",
    "isx.extraProperties.idps.channel": "Channel",
    "ideas.pixelsPerCm": "Calibration (px/cm)",
  } as const,
  event_aligned_data: {
    event_type: "Event type",
    "ideas.metrics.num_valid_events": "Number of events",
    "ideas.metrics.num_up_modulated_cells": "Number of up-modulated cells",
    "ideas.metrics.num_down_modulated_cells": "Number of down-modulated cells",
    "ideas.metrics.num_non_modulated_cells": "Number of non-modulated cells",
    "ideas.timingInfo.numTimes": "Number of timepoints",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Sampling Rate (Hz)",
  } as const,
  event_aligned_neural_data: {
    event_type: "Event type",
    "ideas.metrics.num_valid_events": "Number of events",
    "ideas.metrics.num_up_modulated_cells": "Number of up-modulated cells",
    "ideas.metrics.num_down_modulated_cells": "Number of down-modulated cells",
    "ideas.metrics.num_non_modulated_cells": "Number of non-modulated cells",
    "ideas.timingInfo.numTimes": "Number of timepoints",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Sampling Rate (Hz)",
  } as const,
  statistics: {
    event_type: "Event type",
    "ideas.metrics.num_valid_events": "Number of events",
    "ideas.metrics.num_up_modulated_cells": "Number of up-modulated cells",
    "ideas.metrics.num_down_modulated_cells": "Number of down-modulated cells",
    "ideas.metrics.num_non_modulated_cells": "Number of non-modulated cells",
    "ideas.timingInfo.numTimes": "Number of timepoints",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Sampling Rate (Hz)",
  } as const,
  gpio_data: {
    "isx.extraProperties.microscope.type": "Miniscope",
    "isx.extraProperties.probe.name": "Probe",
    "isx.extraProperties.microscope.serial": "Miniscope serial number",
    "ideas.metrics.num_channels": "Number of channels",
    "isx.channel list": "Channel names",
    "ideas.timingInfo.numTimes": "Number of timepoints per channel",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
  } as const,
  imu_data: {
    "isx.extraProperties.microscope.type": "Miniscope",
    "isx.extraProperties.probe.name": "Probe",
    "isx.extraProperties.microscope.serial": "Miniscope serial number",
    "ideas.metrics.num_channels": "Number of channels",
    "isx.channel list": "Channel names",
    "ideas.timingInfo.numTimes": "Number of timepoints per channel",
    "isx.extraProperties.microscope.fps.fps": "Acquisition Sampling Rate (Hz)",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
  } as const,
  timestamp_events: {
    "ideas.metrics.total_num_events": "Number of events",
    "ideas.metrics.num_event_types": "Number of event types",
    "ideas.metrics.event_types": "Event types",
    "ideas.metrics.num_events_per_type": "Number of events per event type",
  } as const,
  neural_events: {
    "ideas.timingInfo.numFrames": "Number of frames",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
    "ideas.metrics.total_num_cells": "Number of cells",
    "ideas.timingInfo.numSamples": "Number of events per cell",
    "isx.channel list": "Cell names",
    "isx.extraProperties.idps.efocus": "E-focus",
    "isx.extraProperties.idps.channel": "Channel",
    "isx.extraProperties.microscope.widefield": "Widefield",
    "ideas.Cell Extraction Method": "Cell Extraction Method",
    "ideas.Number of Frames": "Number of Frames",
    "ideas.Sampling Rate (Hz)": "Sampling Rate (Hz)",
    "ideas.Recording Duration (s)": "Recording Duration (s)",
    "ideas.Average event rate (Hz)": "Average event rate (Hz)",
    "ideas.Minimum event rate (Hz)": "Minimum event rate (Hz)",
    "ideas.Maximum event rate (Hz)": "Maximum event rate (Hz)",
  } as const,
  image: {
    [DIMENSIONAL_METADATA_KEYS.x]: "Width (pixels)",
    [DIMENSIONAL_METADATA_KEYS.y]: "Height (pixels)",
    [DIMENSIONAL_METADATA_KEYS.z]: "Number of images", // for single-image and multi-image tiff files
  } as const,
  miniscope_image: {
    [DIMENSIONAL_METADATA_KEYS.x]: "Width (pixels)",
    [DIMENSIONAL_METADATA_KEYS.y]: "Height (pixels)",
  } as const,
  unknown: {
    "ideas.metrics.num_rows": "Number of rows",
    "ideas.metrics.num_columns": "Number of columns",
  },
  vessel_set: {},
  bundle: {},
  report: {},
  behavior_annotations: {
    "ideas.metrics.num_rows": "Number of rows",
    "ideas.metrics.num_columns": "Number of columns",
    "ideas.dataset.states": "States",
  },
  combined_peri_event_traces: {
    "ideas.metrics.num_up_modulated_cells": "Number of up-modulated cells",
    "ideas.metrics.num_down_modulated_cells": "Number of down-modulated cells",
    "ideas.metrics.num_non_modulated_cells": "Number of non-modulated cells",
    "ideas.timingInfo.numTimes": "Number of timepoints",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
  },
  combined_peri_event_statistics: {
    "ideas.metrics.num_up_modulated_cells": "Number of up-modulated cells",
    "ideas.metrics.num_down_modulated_cells": "Number of down-modulated cells",
    "ideas.metrics.num_non_modulated_cells": "Number of non-modulated cells",
    "ideas.timingInfo.numTimes": "Number of timepoints",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
  },
  peri_event_comparison_data: {
    "ideas.timingInfo.numTimes": "Number of timepoints",
    "ideas.timingInfo.sampling_rate": "Sampling Rate (Hz)",
  },
  combined_population_activity_data: {
    "ideas.dataset.states": "States",
    "ideas.metrics.total_num_cells": "Number of cells",
  },
  population_activity_comparison_data: {
    "ideas.dataset.states": "States",
    "ideas.metrics.total_num_cells_group1": "Number of cells (first group)",
    "ideas.metrics.total_num_cells_group2": "Number of cells (second group)",
  },
  correlation_comparison_data: {
    "ideas.dataset.states": "States",
    "ideas.metrics.total_num_cells_group1": "Number of cells (first group)",
    "ideas.metrics.total_num_cells_group2": "Number of cells (second group)",
  },
  calcium_image_qc_report: {},
  calcium_image_qc_data: {},
  dlc_model_predictions_data: {
    "ideas.dlc.task": "Model task name",
    "ideas.dlc.scorer": "Model scorer",
    "ideas.dlc.date": "Model training date",
    "ideas.dlc.multi_animal_project": "Multi-animal project",
    "ideas.dlc.model_neural_net_type": "Model neural network type",
    "ideas.dlc.body_parts": "Body parts",
    "ideas.dlc.snapshot": "Model snapshot number",
  },
  nwb_data: {
    "ideas.dataset.session_start_time": "Session start time",
    "ideas.dataset.dandi_compliant": "DANDI compliant",
  },
  experiment_annotations: {
    "ideas.metrics.num_rows": "Number of rows",
    "ideas.metrics.num_columns": "Number of columns",
    "ideas.dataset.states": "States",
    "ideas.recording_uuid": "Recording UUID",
    "ideas.total_distance_traveled_px": "Total distance traveled (px)",
    "ideas.min_speed_px_s": "Minimum speed (px/s)",
    "ideas.max_speed_px_s": "Maximum speed (px/s)",
    "ideas.mean_speed_px_s": "Mean speed (px/s)",
    "ideas.median_speed_px_s": "Median speed (px/s)",
    "ideas.std_speed_px_s": "Standard deviation speed (px/s)",
    "ideas.total_distance_traveled_cm": "Total distance traveled (cm)",
    "ideas.min_speed_cm_s": "Minimum speed (cm/s)",
    "ideas.max_speed_cm_s": "Maximum speed (cm/s)",
    "ideas.mean_speed_cm_s": "Mean speed (cm/s)",
    "ideas.median_speed_cm_s": "Median speed (cm/s)",
    "ideas.std_speed_cm_s": "Standard deviation speed (cm/s)",
    "ideas.time_spent_resting": "Time spent resting (s)",
    "ideas.time_spent_moving": "Time spent moving (s)",
    "ideas.dlc.task": "Model task name",
    "ideas.dlc.scorer": "Model scorer",
    "ideas.dlc.date": "Model training date",
    "ideas.dlc.multi_animal_project": "Multi-animal project",
    "ideas.dlc.model_neural_net_type": "Model neural network type",
    "ideas.dlc.body_parts": "Body parts",
    "ideas.dlc.snapshot": "Model snapshot number",
    "ideas.column_names": "Column Names",
  },
  suite2p_data: {
    "ideas.Cell Extraction Method": "Cell Extraction Method",
    "ideas.Number of Frames": "Number of Frames",
    "ideas.Sampling Rate (Hz)": "Sampling Rate (Hz)",
    "ideas.Recording Duration (s)": "Recording Duration (s)",
    "ideas.Number of Planes": "Number of Planes",
    "ideas.Number of Channels": "Number of Channels",
    "ideas.Frame Width (px)": "Frame Width (px)",
    "ideas.Frame Height (px)": "Frame Height (px)",
    "ideas.Number of Extracted ROIs": "Number of Extracted ROIs",
    "ideas.Number of Accepted ROIs": "Number of Accepted ROIs",
    "ideas.Number of Rejected ROIs": "Number of Rejected ROIs",
    "ideas.Average ROI Radius (px)": "Average ROI Radius (px)",
    "ideas.Average trace amplitude": "Average trace amplitude",
    "ideas.Average event rate (Hz)": "Average event rate (Hz)",
    "ideas.Minimum event rate (Hz)": "Minimum event rate (Hz)",
    "ideas.Maximum event rate (Hz)": "Maximum event rate (Hz)",
  },
  dlc_model: {
    "ideas.dlc.task": "Model task name",
    "ideas.dlc.scorer": "Model scorer",
    "ideas.dlc.date": "Model training date",
    "ideas.dlc.multi_animal_project": "Multi-animal project",
    "ideas.dlc.model_neural_net_type": "Model neural network type",
    "ideas.dlc.body_parts": "Body parts",
    "ideas.dlc.snapshot": "Model snapshot number",
    "ideas.dlc.snapshot_median_train_error":
      "Model snapshot median train error",
    "ideas.dlc.snapshot_median_train_pcutoff_error":
      "Model snapshot median error with p-cutoff",
    "ideas.dlc.snapshot_median_test_error": "Model snapshot median test error",
    "ideas.dlc.snapshot_median_test_pcutoff_error":
      "Model snapshot median test error with p-cutoff",
  },
  dlc_evaluation_results: {
    "ideas.dlc.task": "Model task name",
    "ideas.dlc.scorer": "Model scorer",
    "ideas.dlc.date": "Model training date",
    "ideas.dlc.multi_animal_project": "Multi-animal project",
    "ideas.dlc.model_neural_net_type": "Model neural network type",
    "ideas.dlc.body_parts": "Body parts",
    "ideas.dlc.snapshot": "Model snapshot number",
    "ideas.dlc.snapshot_median_train_error":
      "Model snapshot median train error",
    "ideas.dlc.snapshot_median_train_pcutoff_error":
      "Model snapshot median error with p-cutoff",
    "ideas.dlc.snapshot_median_test_error": "Model snapshot median test error",
    "ideas.dlc.snapshot_median_test_pcutoff_error":
      "Model snapshot median test error with p-cutoff",
  },
  // SHOW ALL METADATA
  modulation_data: undefined,
  correlation_data: undefined,
  movie_zones: undefined,
  zone_occupancy_analysis: undefined,
  locomotion_comparison_data: undefined,
  config: undefined,
  bruker2p_movie: undefined,
  combined_correlation_data: undefined,
  suite2p_classifier: undefined,
  caiman_data: undefined,
  dlc_labeled_data: undefined,
  dlc_config: undefined,
};

// @TODO ID-2041 remove once DLC movies have their own type
export const UNSELECTABLE_METADATA_KEYS = [
  "dlc.model_neural_net_type",
  "dlc.body_parts",
];

import { useCallback, useEffect, useState } from "react";
import { useDrsFileDownloadPreviewDjango } from "hooks/useDrsFileDownloadPreviewDjango";
import { captureException } from "@sentry/react";
import { FILE_FORMATS_BY_KEY } from "types/FileFormats";
import axios from "axios";
import lodash from "lodash";
import {
  BokehCellNames,
  BokehDocJson,
  BokehStatuses,
  CellStatusCellEvents,
  CellStatusCellTraces,
} from "components/CellStatusEditor/CellStatusEditor.types";
import {
  getQcReportImagesFromBokeh,
  getQcReportMetricsFromBokeh,
  getQcReportTracesAndEventsFromBokeh,
} from "./useQcReport.helpers";
import { isDefined } from "utils/isDefined";

export type QcReportData = {
  cellSet:
    | {
        cellInfo: {
          cellNames: BokehCellNames | undefined;
          cellStatuses: BokehStatuses["array"] | undefined;
        };
        cellMetrics: {
          snr: number[] | undefined;
          medianDecay: number[] | undefined;
          meanEventRate: number[] | undefined;
          sdDecay: number[] | undefined;
          circularity: number[] | undefined;
          perimeters: number[] | undefined;
          gof: number[] | undefined;
          maxCorr: number[] | undefined;
          rho: number[] | undefined;
          skew: number[] | undefined;
          areas: number[] | undefined;
        };
        cellContours: {
          contoursX: number[][] | undefined;
          contoursY: number[][] | undefined;
        };
        cellPosition: {
          positionX: number[] | undefined;
          positionY: number[] | undefined;
        };
      }
    | undefined;
  tracesAndEvents:
    | {
        traces: CellStatusCellTraces | undefined;
        events: CellStatusCellEvents;
      }
    | undefined;
  imageData:
    | {
        label: string;
        image: number[][];
      }[]
    | undefined;
};

/**
 * Hook for fetching and parsing cell information, metrics, traces, footprints, and events as well as computed images from the original movie
 * Abandon all hope ye who enter here
 */
export const useQcReport = (
  qcReportFileId?: string,
):
  | { loading: false; data: QcReportData; error?: undefined }
  | { loading: true; data?: undefined; error?: undefined }
  | { loading: false; data?: undefined; error: Error } => {
  const [error, setError] = useState<Error | undefined>();
  const [qcReportData, setQcReportData] = useState<QcReportData | undefined>();

  // we start reasonably enough by fetching the preview URL to get the QC report data
  const { downloadFilePreview } = useDrsFileDownloadPreviewDjango();

  const fetchPreviewUrl = useCallback(async () => {
    if (isDefined(qcReportFileId)) {
      try {
        const { data } = await downloadFilePreview(qcReportFileId);
        const preview = data.previews?.find(
          ({ file_format }) => file_format === FILE_FORMATS_BY_KEY["html"].id,
        );

        if (preview === undefined) {
          throw new Error("Unable to fetch QC report file");
        }

        return preview.url;
      } catch (err) {
        captureException(err);
        setError(err as Error);
      }
    }
  }, [downloadFilePreview, qcReportFileId]);

  /*
   * Extracts cell set info, metrics, traces, events, events, and images from
   * a Bokeh JSON object
   */
  const parseQcReportData = useCallback((bokehData: BokehDocJson) => {
    // the contents of the QC report are a deeply nested JSON object
    // with no real descriptive propeties or intuitive structure
    // there is only ever one root element, so we start by retrieving its children
    try {
      const rootAttributeChildren = lodash.get(
        bokehData,
        "roots[0].attributes.children",
      );
      // if we didn't find that, it isn't a complete QC report
      if (
        rootAttributeChildren === undefined ||
        !Array.isArray(rootAttributeChildren)
      ) {
        throw new Error(
          "Failed to find root attribute children in QC report data.",
        );
      }
      // the relevant data is stored in three separate groups, hence the three helper fns
      // we reorganize these later into more useful structures
      const cellSet = getQcReportMetricsFromBokeh(bokehData);
      const tracesAndEvents = getQcReportTracesAndEventsFromBokeh(bokehData);
      const imageData = getQcReportImagesFromBokeh(bokehData);
      setQcReportData({
        cellSet,
        tracesAndEvents,
        imageData,
      });
    } catch (err) {
      captureException(err);
      setError(err as Error);
    }
  }, []);

  useEffect(() => {
    // if we have a file ID and haven't already fetched the QC report data
    // then we fetch the preview URL and then fetch the HTML file
    if (isDefined(qcReportFileId) && !qcReportData && !error) {
      fetchPreviewUrl()
        .then((url) => {
          if (url === undefined) {
            throw new Error("Failed to fetch QC report URL.");
          }
          axios
            .get<string | undefined>(url)
            .then((res) => {
              if (res.data === undefined) {
                throw new Error("Failed to fetch QC report data.");
              }
              // the response is a string of HTML, so we parse it into a document
              const parser = new DOMParser();
              const htmlDoc = parser.parseFromString(res.data, "text/html");
              // all the relevant data is stored in a single JSON script tag
              // there's only one in the document, so we just grab the first one
              const jsonScript = htmlDoc.querySelector(
                'script[type="application/json"]',
              );
              if (jsonScript === null) {
                throw new Error(
                  "Failed to parse QC report file, could not find JSON data",
                );
              }
              // all the data is nested under a UUID key that we can't know ahead of time
              // this just grabs the first key in the object and uses that as the key
              const json = JSON.parse(jsonScript.innerHTML) as {
                [key: string]: BokehDocJson;
              };
              const bokehData = json[Object.keys(json)[0]];
              parseQcReportData(bokehData);
            })
            .catch((err) => setError(err as Error));
        })
        .catch((err) => setError(err as Error));
    }
  }, [error, fetchPreviewUrl, parseQcReportData, qcReportData, qcReportFileId]);

  if (qcReportFileId === undefined) {
    return { loading: false, error: new Error("No QC report file selected") };
  }

  if (qcReportData !== undefined) {
    return { loading: false, data: qcReportData };
  }

  if (error !== undefined) {
    return { loading: false, error };
  }

  return { loading: true };
};

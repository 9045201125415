/** @jsxImportSource @emotion/react */
import {
  EuiBottomBar,
  EuiBreadcrumbs,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiText,
  EuiThemeProvider,
  EuiTitle,
} from "@inscopix/ideas-eui";
import {
  AnalysisTable as IAnalysisTable,
  AnalysisTableGroup,
  Project,
  Tool,
  ToolVersion,
} from "graphql/_Types";
import { ToolParamsGrid } from "components/ToolParamsGrid/ToolParamsGrid";

import {
  StatusPanelAddRow,
  StatusPanelSaveStatus,
} from "../ToolParamsGrid/hooks/useStatusBar";
import { css } from "@emotion/react";
import { useMemo, useState } from "react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { ModalEditAnalysisTableGroup } from "./ModalEditAnalysisTableGroup";
import { useToolParamsGridContext } from "components/ToolParamsGrid/ToolParamsGridProvider";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useRoutes } from "hooks/useRoutes";
import { ButtonToolDocs } from "components/ButtonToolDocs/ButtonToolDocs";
import { Helmet } from "react-helmet-async";
import { DatasetBreadcrumbMenu } from "../DatasetBreadcrumbMenu/DatasetBreadcrumbMenu";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ToolBetaBadge } from "components/ToolBetaBadge/ToolBetaBadge";
import { ToolMaturity } from "types/constants";
import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { isNonNullish } from "utils/isNonNullish";
import { AnalysisTableMenuBar } from "./AnalysisTableMenuBar";
import { AnalysisTableStartJobs } from "./AnalysisTableStartJobs";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useToolSpecContext } from "providers/ToolSpecProvider/ToolSpecProvider";
import { chain } from "lodash";
import { compare } from "semver";
import { AnalysisTableButtonCreateTab } from "./AnalysisTableButtonCreateTab";
import { AnalysisTableButtonTab } from "./AnalysisTableButtonTab";
import { roundToSignificant } from "utils/roundToSignificant";

export interface AnalysisTableProps {
  tool: Pick<Tool, "id" | "name" | "key">;
  group: {
    id: AnalysisTableGroup["id"];
    name: string;
  };
  tables: {
    id: IAnalysisTable["id"];
    name: string;
    toolVersions: {
      version: ToolVersion["version"];
      maturity: ToolVersion["maturity"];
      credits: ToolVersion["credits"];
    }[];
    totalCredits: number;
    projectId: Project["id"];
    userId: IAnalysisTable["userId"];
    tenantId: IAnalysisTable["tenantId"];
  }[];
  selectedTableId: string | undefined;
  setSelectedTableId: (tableId: string) => Promise<void>;
}

const bottomBarStyle = css`
  border: 1px solid #babfc7;
  border-top: 0px;
  background: #f8f8f8;
  box-shadow: none;
`;

export const AnalysisTable = ({
  tool,
  group,
  tables,
  selectedTableId,
  setSelectedTableId,
}: AnalysisTableProps) => {
  const { routeMap } = useRoutes();
  const { project, datasets } = useProjectDataContext();
  const { rightFlyout, modal } = useAnalysisTableLayoutContext();
  const { toolSpec } = useToolParamsGridContext();
  const [showEditModal, setShowEditModal] = useState(false);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { availableToolSpecs } = useToolSpecContext();

  const rightFlyoutWidth = 400;
  const styles = {
    root: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 500px;
      min-width: ${isNonNullish(rightFlyout) ? rightFlyoutWidth + 800 : 800}px;
    `,
    content: css`
      display: flex;
      flex: 1;
    `,
    flyout: css`
      width: ${rightFlyoutWidth}px !important;
    `,
    grid: css`
      flex: 3.5;
      .ag-header-cell.dataObjectColHeader,
      .ag-cell.dataObjectCell {
        border-left: 2px solid #bbb;
      }
      .ag-header-cell.analysisOutputColHeader,
      .ag-cell.analysisOutputCell {
        border-left: 2px solid #888;
      }
    `,
    gridAndFlyoutWrapper: css`
      display: flex;
      height: 100%;
      flex-grow: 1;
      overflow: hidden;
    `,
    calloutContainer: css`
      display: flex;
      padding: 0px 12px;
    `,
    calloutText: css`
      display: inline-flex;
      height: 32px;
      align-items: center;
    `,
  };

  const selectedTable = tables.find((table) => table.id === selectedTableId);

  const newestToolVersion = useMemo(() => {
    const latestToolSpec = chain(availableToolSpecs)
      .filter((spec) => spec.toolId === tool.id)
      .sort((a, b) => compare(a.spec.version, b.spec.version))
      .last()
      .value();

    const isNewToolVersion = !tables.some((table) =>
      table.toolVersions.some(
        (version) => latestToolSpec.spec.version === version.version,
      ),
    );

    return isNewToolVersion ? latestToolSpec : undefined;
  }, [availableToolSpecs, tables, tool]);

  const totalUsage = roundToSignificant(
    chain(tables)
      .sumBy((table) => table.totalCredits)
      .value(),
  );

  const AnalysisTableHeader = (
    <EuiPageHeader bottomBorder={false} paddingSize="m" alignItems={"bottom"}>
      <Helmet>
        <title>{`${project.name}: ${group.name}`}</title>
      </Helmet>

      <EuiPageHeaderSection>
        <EuiBreadcrumbs
          data-test-subj="analysis-table-breadcrumbs"
          truncate={false}
          aria-label="breadcrumbs"
          breadcrumbs={[
            {
              text: (
                <>
                  <EuiIcon size="s" type="arrowLeft" />
                  {project.name}
                </>
              ),
              "aria-current": false,
              href: routeMap["PROJECT"].dynamicPath({
                tenantKey: currentTenant.key,
                projectKey: project.key,
              }).path,
              onClick: (e) => {
                e.preventDefault();
                routeMap["PROJECT"]
                  .dynamicPath({
                    tenantKey: currentTenant.key,
                    projectKey: project.key,
                  })
                  .navigateTo();
              },
            },
            ...(datasets.length !== 0 //removes the tailing slash / when there is no dataset
              ? [
                  {
                    text: <DatasetBreadcrumbMenu />,
                    "aria-current": false,
                  },
                ]
              : []),
          ]}
        />
        <EuiTitle size="m">
          <h1 data-test-subj="dataset-header-title">
            <>
              {group.name}
              <ButtonIconPermissioned
                data-test-subj="edit-analysis-table-name"
                aria-label="Edit analysis table name"
                key="edit-analysis-table"
                size={"s"}
                iconSize={"m"}
                color="text"
                iconType="pencil"
                onClick={() => setShowEditModal(true)}
                requiredPermission="edit"
                defaultTooltip="Edit analysis table name"
              />
              <StatusPanelSaveStatus />
              <br />
              <EuiText data-test-subj="workflow-info" color="subdued" size="s">
                <EuiIcon type="indexMapping" />
                &nbsp; <strong>{tool.name} </strong> - uses&nbsp;
                <Tooltip
                  content={
                    <>
                      <b>vCPU Count:</b> {toolSpec.resources.cpu}
                      <br />
                      <b>RAM:</b>{" "}
                      {toolSpec.resources.memory !== undefined
                        ? `${toolSpec.resources.memory / 1000}GiB`
                        : "undefined"}
                    </>
                  }
                >
                  {
                    chain([...(selectedTable?.toolVersions ?? [])])
                      .sort((a, b) => compare(a.version, b.version))
                      .last()
                      .value()?.credits
                  }{" "}
                  compute credits/hour{" "}
                </Tooltip>
                &nbsp;&nbsp;
                {selectedTable?.toolVersions[0]?.maturity ===
                  ToolMaturity.DEVELOPMENT && <ToolBetaBadge />}
                <ButtonToolDocs
                  data-test-subj="tool-docs-button"
                  tool={{
                    key: tool.key,
                  }}
                  EuiButtonComponent={EuiButtonEmpty}
                />
              </EuiText>
            </>
          </h1>
        </EuiTitle>
      </EuiPageHeaderSection>
      <EuiPageHeaderSection>
        <EuiFlexGroup justifyContent={"flexEnd"}>
          {/** Blocksize is used to visually align this with the subtitle of the page on the left*/}
          <EuiText size="s" style={{ blockSize: "26px" }}>
            Total usage: <b>{totalUsage} compute credits</b>
          </EuiText>
        </EuiFlexGroup>
      </EuiPageHeaderSection>
    </EuiPageHeader>
  );

  const AnalysisTableBottomBar = (
    <EuiBottomBar css={bottomBarStyle} position="static" bottom={0}>
      <EuiThemeProvider colorMode={"light"}>
        <EuiFlexGroup justifyContent="spaceBetween" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <StatusPanelAddRow />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s" responsive={false}>
              <EuiFlexItem grow={false}>
                {selectedTableId !== undefined && (
                  <AnalysisTableStartJobs
                    projectId={project.id}
                    projectKey={project.key}
                    analysisTableId={selectedTableId}
                  />
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiThemeProvider>
    </EuiBottomBar>
  );

  return (
    <>
      <div css={styles.root}>
        {AnalysisTableHeader}
        {selectedTable && (
          <AnalysisTableMenuBar group={group} table={selectedTable} />
        )}
        <EuiHorizontalRule margin="none" />
        <EuiFlexGroup
          style={{ backgroundColor: "#f5f7f7", padding: 5 }}
          gutterSize="s"
        >
          {newestToolVersion && selectedTable && (
            <EuiFlexItem grow={false}>
              <AnalysisTableButtonCreateTab
                analysisTableGroupId={group.id}
                projectId={selectedTable.projectId}
                toolVersionId={newestToolVersion.toolVersionId}
                setSelectedTableId={setSelectedTableId}
              />
            </EuiFlexItem>
          )}

          {tables.map((table) => {
            return (
              <EuiFlexItem key={table.id} grow={false}>
                <AnalysisTableButtonTab
                  isSelected={selectedTableId === table.id}
                  table={table}
                  setSelectedTableId={setSelectedTableId}
                />
              </EuiFlexItem>
            );
          })}
        </EuiFlexGroup>
        <div css={styles.gridAndFlyoutWrapper}>
          <div css={styles.grid}>
            <ToolParamsGrid />
          </div>
          {isNonNullish(rightFlyout) && (
            <div css={styles.flyout}>{rightFlyout.node}</div>
          )}
        </div>{" "}
        {AnalysisTableBottomBar}
      </div>
      {modal}
      {showEditModal && (
        <ModalEditAnalysisTableGroup
          onClose={() => setShowEditModal(false)}
          onError={() => addUtilityToastFailure("Failed to edit table")}
          onSuccess={() => addUtilityToastSuccess("Successfully edited table")}
          analysisTableGroup={group}
        />
      )}
    </>
  );
};

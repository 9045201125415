import assert from "assert";
import { useUpdatePageDatasetCache } from "components/Dataset/hooks/useUpdatePageDatasetCache";
import { Metadatum, FileRecordingGroup } from "graphql/_Types";
import { useDatasetDataContext } from "pages/project/dataset/DatasetDataProvider";
import { useCallback } from "react";
import { isDefined } from "utils/isDefined";
import { cloneDeep } from "lodash";
import { JsonValue } from "type-fest";
import { useRecordingMetadataValueCreateBulkDjango } from "hooks/useRecordingMetadataValueCreateBulkDjango";
import { isNonNull } from "utils/isNonNull";
import { uuid } from "utils/uuid";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { updateCacheFragment } from "utils/cache-fragments";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useProjectPermission } from "hooks/useProjectPermission";
import { useUserContext } from "providers/UserProvider/UserProvider";

export type CreateRecordingMetadataParams = {
  dateCreated: string;
  recordingId: FileRecordingGroup["id"];
  metadataKey: Metadatum["key"];
  value: JsonValue;
}[];

export const useActionCreateRecordingMetadataValues = () => {
  const { gridRef } = useDatasetLayoutContext();
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { dataset, datasetMode, project } = useDatasetDataContext();
  const { updateCache } = useUpdatePageDatasetCache(dataset.id);
  const { createRecordingMetadata } =
    useRecordingMetadataValueCreateBulkDjango();
  const { hasPermission } = useProjectPermission();

  const getActionDef = useCallback(
    (params: CreateRecordingMetadataParams) => {
      return {
        onEnqueue: () => {
          updateCache((prevData) => {
            const newData = cloneDeep(prevData);
            const table = newData.dataset.datasetRecordingsTable;
            const recordings = table.fileRecordingGroups.nodes;

            params.forEach((param) => {
              const recording = recordings.find(
                ({ id }) => id === param.recordingId,
              );
              assert(isDefined(recording));

              const metadata = recording.metadata.nodes
                .map(({ metadatum }) => metadatum)
                .filter(isNonNull);

              let metadatum = metadata.find(
                ({ key }) => key === param.metadataKey,
              );

              if (metadatum === undefined) {
                metadatum = {
                  __typename: "Metadatum",
                  id: uuid(),
                  key: param.metadataKey,
                  displayName: param.metadataKey,
                  tenantId: currentTenant.id,
                  values: {
                    __typename: "MetadataValuesConnection",
                    nodes: [],
                  },
                };

                recording.metadata.nodes.push({
                  __typename: "FileRecordingGroupMetadatum",
                  id: uuid(),
                  dateCreated: param.dateCreated,
                  tenantId: currentTenant.id,
                  metadataId: metadatum.id,
                  fileRecordingGroupId: param.recordingId,
                  metadatum,
                });
              }

              metadatum.values.nodes = [
                {
                  __typename: "MetadataValue",
                  id: uuid(),
                  dateCreated: param.dateCreated,
                  metadataId: metadatum.id,
                  taskId: null,
                  tenantId: currentTenant.id,
                  userId: currentUser.id,
                  value: param.value,
                  projectId: project.id,
                },
              ];

              updateCacheFragment({
                __typename: "Metadatum",
                id: metadatum.id,
                update: (data) => {
                  const newData = cloneDeep(data);
                  newData.activeValue = param.value;
                  return newData;
                },
              });
            });

            return newData;
          });
        },

        onDequeue: async () => {
          const inputs = params.map((param) => ({
            file_recording_group: param.recordingId,
            metadata_key: param.metadataKey,
            value: param.value,
            date_created: param.dateCreated,
          }));
          const result = await createRecordingMetadata(inputs);
          // Redraw recording identifiers
          // TODO: This will be removed when identifiers are calculated on the backend
          // The timeout prevents an internal AG Grid error when redrawing rows
          setTimeout(() => gridRef.current?.api.redrawRows(), 0);
          return result;
        },
      };
    },
    [
      createRecordingMetadata,
      currentTenant.id,
      currentUser.id,
      gridRef,
      project.id,
      updateCache,
    ],
  );
  const isDisabled = datasetMode.type !== "current" || !hasPermission("edit");

  return {
    getActionDef,
    isDisabled,
  };
};

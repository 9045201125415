import {
  BokehCellNames,
  BokehContours,
  BokehDocJson,
  BokehEntries,
  BokehEntryAttributes,
  BokehEvents,
  BokehNDArrayRep,
  BokehStatuses,
  CellStatusEditorImageData,
} from "components/CellStatusEditor/CellStatusEditor.types";
import { get, isUndefined } from "lodash";
import { isDefined } from "utils/isDefined";

/**
 * Retrieves QC report metrics from Bokeh JSON document
 *
 * @param bokehData - The Bokeh data object.
 * @returns An object containing the cell information, cell metrics, and cell contours.
 */
export function getQcReportMetricsFromBokeh(bokehData: BokehDocJson) {
  // ok wait let me explain
  // so the bokeh data is a JSON object that contains a lot of nested data
  // and it's not very well documented, and not organized in any semantically reasonable way
  // data is buried under random toolbar attributes
  // I would love to find a better way to do this, but right now the best I have is that the
  // there's basically two possible paths to the data depending on whether or not the QC report
  // includes event data - these are just hardcoded into gets right now
  // we try both and one should work
  let cellMetricEntries = get(
    bokehData,
    "roots[0].attributes.children[2].attributes.children[0].attributes.children[1].attributes.children[0].attributes.children[0].attributes.toolbar.attributes.tools[4].attributes.renderers[0].attributes.data_source.attributes.data.entries",
  ) as BokehEntries | undefined;

  if (cellMetricEntries === undefined) {
    cellMetricEntries = get(
      bokehData,
      "roots[0].attributes.children[3].attributes.children[0].attributes.children[1].attributes.children[0].attributes.children[0].attributes.renderers[0].attributes.data_source.attributes.data.entries",
    ) as BokehEntries | undefined;
  }
  // if there's no cell metric data, return undefined
  if (isUndefined(cellMetricEntries)) {
    return undefined;
  } else {
    // extract relevant data and transform it into more useful data types and structures

    // CELL NAMES
    const cellNamesArray = cellMetricEntries.find(
      ([key]) => key === "cell_names",
    );
    const cellNames = cellNamesArray?.[1] as BokehCellNames | undefined;

    // STATUS
    const statusArray = cellMetricEntries.find(([key]) => key === "status");
    const cellStatuses = (statusArray?.[1] as BokehStatuses | undefined)?.array;

    // SNR
    const snrArray = cellMetricEntries.find(([key]) => key === "snr");
    const snrData = snrArray?.[1] as BokehNDArrayRep | undefined;
    const snr = isDefined(snrData) ? decodeBokehNdArray(snrData) : undefined;

    // MEDIAN DECAY
    const medianDecayArray = cellMetricEntries.find(
      ([key]) => key === "median_decay",
    );
    const medianDecayData = medianDecayArray?.[1] as
      | BokehNDArrayRep
      | undefined;
    const medianDecay = isDefined(medianDecayData)
      ? decodeBokehNdArray(medianDecayData)
      : undefined;

    // MEAN EVENT RATE
    const meanEventRateArray = cellMetricEntries.find(
      ([key]) => key === "mean_event_rate",
    );
    const meanEventRateData = meanEventRateArray?.[1] as
      | BokehNDArrayRep
      | undefined;
    const meanEventRate = isDefined(meanEventRateData)
      ? decodeBokehNdArray(meanEventRateData)
      : undefined;

    // SD DECAY
    const sdDecayArray = cellMetricEntries.find(([key]) => key === "sd_decay");
    const sdDecayData = sdDecayArray?.[1] as BokehNDArrayRep | undefined;
    const sdDecay = isDefined(sdDecayData)
      ? decodeBokehNdArray(sdDecayData)
      : undefined;

    // CIRCULARITY
    const circularityArray = cellMetricEntries.find(
      ([key]) => key === "circularity",
    );
    const circularityData = circularityArray?.[1] as
      | BokehNDArrayRep
      | undefined;
    const circularity = isDefined(circularityData)
      ? decodeBokehNdArray(circularityData)
      : undefined;

    // PERIMETERS
    const perimetersArray = cellMetricEntries.find(
      ([key]) => key === "perimeters",
    );
    const perimetersData = perimetersArray?.[1] as BokehNDArrayRep | undefined;
    const perimeters = isDefined(perimetersData)
      ? decodeBokehNdArray(perimetersData)
      : undefined;

    // GOF
    const gofArray = cellMetricEntries.find(([key]) => key === "gof");
    const gofData = gofArray?.[1] as BokehNDArrayRep | undefined;
    const gof = isDefined(gofData) ? decodeBokehNdArray(gofData) : undefined;

    // MAX CORR
    const maxCorrArray = cellMetricEntries.find(([key]) => key === "max_corr");
    const maxCorrData = maxCorrArray?.[1] as BokehNDArrayRep | undefined;
    const maxCorr = isDefined(maxCorrData)
      ? decodeBokehNdArray(maxCorrData)
      : undefined;

    // RHO
    const rhoArray = cellMetricEntries.find(([key]) => key === "rho");
    const rhoData = rhoArray?.[1] as BokehNDArrayRep | undefined;
    const rho = isDefined(rhoData) ? decodeBokehNdArray(rhoData) : undefined;

    // SKEW
    const skewArray = cellMetricEntries.find(([key]) => key === "skew");
    const skewData = skewArray?.[1] as BokehNDArrayRep | undefined;
    const skew = isDefined(skewData) ? decodeBokehNdArray(skewData) : undefined;

    // POSITION X
    const positionXArray = cellMetricEntries.find(
      ([key]) => key === "position_x",
    );
    const positionXData = positionXArray?.[1] as BokehNDArrayRep | undefined;
    const positionX = isDefined(positionXData)
      ? decodeBokehNdArray(positionXData)
      : undefined;

    // POSITION Y
    const positionYArray = cellMetricEntries.find(
      ([key]) => key === "position_y",
    );
    const positionYData = positionYArray?.[1] as BokehNDArrayRep | undefined;
    const positionY = isDefined(positionYData)
      ? decodeBokehNdArray(positionYData)
      : undefined;

    // AREA
    const areasArray = cellMetricEntries.find(([key]) => key === "areas");
    const areasData = areasArray?.[1] as BokehNDArrayRep | undefined;
    const areas = isDefined(areasData)
      ? decodeBokehNdArray(areasData)
      : undefined;

    // CONTOURS X
    const contoursXArray = cellMetricEntries.find(
      ([key]) => key === "contours_x",
    );
    const contoursXData = contoursXArray?.[1] as BokehContours | undefined;
    const contoursX = isDefined(contoursXData)
      ? contoursXData
          .map((contour) =>
            contour.map((contourArray) =>
              contourArray.map((ndArray) => decodeBokehNdArray(ndArray)),
            ),
          )
          .map((contour) => contour[0][0]) // there's some extra unnecessary nesting
      : undefined;

    // CONTOURS Y
    const contoursYArray = cellMetricEntries.find(
      ([key]) => key === "contours_y",
    );
    const contoursYData = contoursYArray?.[1] as BokehContours | undefined;
    const contoursY = isDefined(contoursYData)
      ? contoursYData
          .map((contour) =>
            contour.map((contourArray) =>
              contourArray.map((ndArray) => decodeBokehNdArray(ndArray)),
            ),
          )
          .map((contour) => contour[0][0]) // there's some extra unnecessary nesting
      : undefined;

    return {
      // basic cell information - this is duplicate data from the cell set metadata
      // but useful to have to confirm a match
      cellInfo: {
        cellNames,
        cellStatuses,
      },
      // metrics usable in the scatter plot
      cellMetrics: {
        snr,
        medianDecay,
        meanEventRate,
        sdDecay,
        circularity,
        perimeters,
        gof,
        maxCorr,
        rho,
        skew,
        areas,
      },
      // contours to draw on the images
      cellContours: {
        contoursX,
        contoursY,
      },
      // cell positions not currently used
      cellPosition: {
        positionX,
        positionY,
      },
    };
  }
}

/**
 * Retrieves traces and events data from Bokeh data.
 *
 * @param bokehData - The Bokeh data object.
 * @returns An object containing traces and events data, or undefined if no data is found.
 */
export function getQcReportTracesAndEventsFromBokeh(bokehData: BokehDocJson) {
  // see above for explanation of this mess
  let entries = get(
    bokehData,
    "roots[0].attributes.children[2].attributes.children[0].attributes.children[1].attributes.children[0].attributes.children[0].attributes.toolbar.attributes.tools[4].attributes.callback.attributes.args.entries[0][1].attributes.js_property_callbacks.entries[0][1][0].attributes.args.entries",
  ) as BokehEntries | undefined;
  if (isUndefined(entries)) {
    entries = get(
      bokehData,
      "roots[0].attributes.children[3].attributes.children[0].attributes.children[1].attributes.children[0].attributes.children[0].attributes.toolbar.attributes.tools[4].attributes.callback.attributes.args.entries[0][1].attributes.js_property_callbacks.entries[0][1][0].attributes.args.entries",
    ) as BokehEntries | undefined;
  }
  // is no traces/event data, return undefined
  if (isUndefined(entries)) {
    return undefined;
  } else {
    // TRACES
    const tracesArray = entries.find(([key]) => key === "traces");
    const tracesData = tracesArray?.[1] as BokehNDArrayRep[] | undefined;
    const traces = isDefined(tracesData)
      ? tracesData.map((trace) => decodeBokehNdArray(trace))
      : undefined;

    // EVENTS
    const eventsArray = entries.find(([key]) => key === "events");
    const eventsData = eventsArray?.[1] as BokehEvents | undefined;
    const eventTimes = eventsData?.entries?.find(
      ([key]) => key === "times",
    )?.[1];
    const times = isDefined(eventTimes)
      ? eventTimes.map((ndArray) => decodeBokehNdArray(ndArray))
      : undefined;

    const eventAmplitudes = eventsData?.entries?.find(
      ([key]) => key === "amplitudes",
    )?.[1];
    const amplitudes = isDefined(eventAmplitudes)
      ? eventAmplitudes.map((ndArray) => decodeBokehNdArray(ndArray))
      : undefined;

    return { traces, events: { times, amplitudes } };
  }
}

/**
 * Retrieves QC report images from Bokeh data.
 *
 * @param bokehData - The Bokeh data object.
 * @returns An array of objects containing labels and corresponding images.
 */
export function getQcReportImagesFromBokeh(bokehData: BokehDocJson) {
  // see above for explanation of this mess
  const attributes = get(
    bokehData,
    "roots[0].attributes.children[2].attributes.children[0].attributes.children[1].attributes.children[1].attributes.children[1].attributes.children[0].attributes",
  ) as BokehEntryAttributes | undefined;
  // if there's no image data, return undefined
  if (isUndefined(attributes)) {
    return undefined;
  } else {
    // this is not per-cell data, it's a single array of images
    // they are encoded as 1d arrays that have to be reshaped
    const labels = attributes.labels;
    const attributesEntry = attributes.js_event_callbacks.entries[0].find(
      (entry) => Array.isArray(entry),
    );
    const imageData = Array.isArray(attributesEntry)
      ? attributesEntry[0].attributes.args.entries.find(
          ([key]) => key === "all_img",
        )
      : undefined;
    const imagesArray = imageData?.[1];
    const images = Array.isArray(imagesArray)
      ? imagesArray?.map((ndArray) => {
          const flatArray = decodeBokehNdArray(ndArray);
          const shapeY = ndArray.shape[1];
          const twoDArray: number[][] = [];
          while (flatArray.length > 0) {
            twoDArray.push(flatArray.splice(0, shapeY));
          }
          return twoDArray;
        })
      : undefined;

    return labels
      .map((label, index) => ({
        label,
        image: images?.[index],
      }))
      .filter(
        (image): image is CellStatusEditorImageData[number] =>
          image !== undefined,
      );
  }
}

/**
 * Converts a base64 string to an ArrayBuffer.
 *
 * @param base64 - The base64 string to convert.
 * @returns The converted ArrayBuffer.
 */
function base64ToArrayBuffer(base64: string) {
  const binary_string = atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * Decodes a BokehNdArray representation into a JavaScript array.
 *
 * @param data - The BokehNdArray representation to decode.
 * @returns The decoded JavaScript array.
 * @throws If the BokehNdArray type or array type is unsupported.
 */
function decodeBokehNdArray(data: BokehNDArrayRep) {
  if (data.array.type === "bytes") {
    const ndArrayData = data.array.data;
    switch (data.dtype) {
      case "float64":
        return Array.from(
          new Float64Array(base64ToArrayBuffer(ndArrayData as string)),
        );
      case "float32":
        return Array.from(
          new Float32Array(base64ToArrayBuffer(ndArrayData as string)),
        );
      case "int32":
        return Array.from(
          new Int32Array(base64ToArrayBuffer(ndArrayData as string)),
        );
      default:
        throw new Error(`Unsupported BokehNdArray type: ${data.dtype}`);
    }
  } else {
    throw new Error(`Unsupported BokehNdArray array type: ${data.array.type}`);
  }
}

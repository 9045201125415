import assert from "assert";
import { useGetAllTasksQuery } from "graphql/_Types";
import { cloneDeep, keyBy } from "lodash";
import { useUpdatePageProjectCache } from "pages/project/hooks/useUpdatePageProjectCache";
import { useProjectDataContext } from "pages/project/ProjectDataProvider";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { TaskStatus } from "types/constants";
import { updateCacheFragment, writeCacheFragment } from "utils/cache-fragments";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";

export const usePollTasks = () => {
  const files = useProjectFilesStore((s) => s.files);
  const { project } = useProjectDataContext();
  const { analysisTable } = useToolParamsGridContext();
  const analysisTableId = analysisTable.id;
  const rowData = useToolParamsGridRowDataContext((s) => s.rowData);
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );
  const { updateCache: updatePageProjectCache } = useUpdatePageProjectCache(
    project.key,
  );

  const polledRows = rowData.filter((rowDatum) => {
    const taskStatus = rowDatum?.task_status;
    const isTaskPending =
      rowDatum.task_id !== undefined &&
      ((taskStatus !== TaskStatus["COMPLETE"] &&
        taskStatus !== TaskStatus["FAILED"] &&
        taskStatus !== TaskStatus["ERROR"] &&
        taskStatus !== TaskStatus["CANCELED"]) ||
        rowDatum.task_compute_credit === undefined ||
        rowDatum.task_duration === undefined);

    // Keep polling for any row with a pending task
    if (isTaskPending) {
      return true;
    }

    // Keep polling for any row with a completed task with results yet to be
    // fetched
    if (taskStatus === TaskStatus["COMPLETE"]) {
      return rowDatum.output_group_files === undefined;
    }

    // Don't poll for any other rows
    return false;
  });

  useGetAllTasksQuery({
    variables: {
      filter: {
        id: {
          in: polledRows.map(({ task_id }) => task_id).filter(isDefined),
        },
      },
    },
    skip:
      polledRows.map(({ task_id }) => task_id).filter(isDefined).length === 0,
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ tasks }) => {
      assert(isNonNull(tasks));
      const tasksById = keyBy(tasks.nodes, (task) => task.id);

      polledRows.forEach((rowDatum) => {
        const { task_id: taskId } = rowDatum;

        if (taskId === undefined) {
          return;
        }

        const task = tasksById[taskId];

        updateRowDatum(
          rowDatum.id,
          {
            task_status: task.status,
            task_date_created: task.created,
            output_group_files: task.outputGroup?.outputGroupFiles.nodes
              .map(({ drsFile }) => drsFile)
              .filter(isNonNull),
            task_compute_credit: task.credits,
            task_duration: task.taskActivityByTaskId?.duration,
          },
          { forceUpdateLockedRow: true, skipSave: true },
        );

        updatePageProjectCache((data) => {
          const newData = cloneDeep(data);
          newData.project.analysisTableGroups.nodes.forEach((group) => {
            group.analysisTables.nodes = group.analysisTables.nodes.map(
              (analysisTable) => {
                if (analysisTable.id === analysisTableId) {
                  analysisTable.rows.nodes = analysisTable.rows.nodes.map(
                    (row) => {
                      if (rowDatum.id === row.id) {
                        const taskId = rowDatum.task_id;
                        if (isDefined(taskId)) {
                          return {
                            ...row,
                            task: {
                              ...task,
                            },
                          };
                        }
                      }

                      return row;
                    },
                  );
                }
                return analysisTable;
              },
            );
          });
          return newData;
        });

        // update cache with results if they exist
        const outputGroup = task.outputGroup;
        if (outputGroup) {
          // we have results
          const outputGroupDrsFiles = outputGroup.outputGroupFiles.nodes;
          outputGroupDrsFiles
            .map(({ drsFile }) => drsFile)
            .filter(isNonNull)
            .forEach((drsFile) => {
              // if object already exists, skip it
              if (files.some((projectFile) => projectFile.id === drsFile.id)) {
                return;
              }
              writeCacheFragment({
                __typename: "File",
                id: drsFile.id,
                data: {
                  ...drsFile,
                },
              });

              // Add the new DRS object to the project cache
              updateCacheFragment({
                __typename: "Project",
                id: drsFile.projectId,
                update: (data) => {
                  const newData = cloneDeep(data);

                  if (newData.activeFiles === undefined) {
                    newData.activeFiles = {
                      __typename: "FilesConnection",
                      nodes: [],
                    };
                  }

                  newData.activeFiles.nodes.push({
                    __typename: "File",
                    id: drsFile.id,
                  });

                  return newData;
                },
              });
            });
        }
      });
    },
  });
};

import assert from "assert";
import { DandiServer } from "components/ModalDandi/ModalDandi.types";
import { ButtonDatasetExportsQuery, ToolParameter } from "graphql/_Types";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { isDefined } from "utils/isDefined";
import { isNonNullish } from "utils/isNonNullish";

/**
 * Looks up the value of a task parameter using the key of a tool parameter.
 * Task parameters are stored as JSON fields so table connections cannot be
 * used to make these associations.
 * @param task
 * @param toolParameterKey
 * @returns The value of the task parameter if one exists or `undefined` otherwise.
 */
const getTaskParameterValue = (
  task: NonNullable<ButtonDatasetExportsQuery["tasks"]>["nodes"][number],
  toolParameterKey: ToolParameter["key"],
) => {
  const taskParameters = task.taskParameters as
    | { value: string; tool_parameter: ToolParameter["id"] }[]
    | null;

  // Find the tool parameter matching the provided key
  const toolParameter = task.toolVersion?.toolParameters.nodes.find(
    (param) => param.key === toolParameterKey,
  );

  // Find the task parameter matching the specified tool parameter
  const taskParameter = taskParameters?.find(
    (param) => param.tool_parameter === toolParameter?.id,
  );

  return taskParameter?.value;
};

/**
 * Validate and format the data returned by the ButtonDatasetExports query.
 * @param data
 * @returns The formatted query data.
 * @throws An error if any data is missing or malformed.
 */
export const validateExportTasks = (data: ButtonDatasetExportsQuery) => {
  const tasks = data.tasks?.nodes;
  assert(isNonNullish(tasks));

  return tasks.map((task) => {
    const files = task.taskSources.nodes.map((source) => {
      const { fileId } = source;
      const projectFiles = useProjectFilesStore.getState().files;
      const file = projectFiles.find(({ id }) => id === fileId);
      assert(isDefined(file));
      return file;
    });

    const dandisetId = getTaskParameterValue(task, "dandiset_id");
    assert(dandisetId !== undefined);

    const server = getTaskParameterValue(task, "instance");
    assert(server !== undefined);

    return {
      task: {
        id: task.id,
        status: task.status,
        dateCreated: task.created,
        userId: task.userId,
      },
      files,
      dandisetId,
      server: server as DandiServer,
    };
  });
};

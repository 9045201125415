import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiButtonIcon,
  EuiLink,
  EuiScreenReaderOnly,
  EuiTableComputedColumnType,
} from "@inscopix/ideas-eui";
import { ButtonViewTaskLogs } from "components/ButtonViewTaskLogs/ButtonViewTaskLogs";
import { FileBadge } from "components/FileBadge/FileBadge";
import { getDandisetUrl } from "components/ModalDandi/ModalDandi.helpers";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import { TaskStatusBadge } from "components/TaskStatusBadge/TaskStatusBadge";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import { useState } from "react";
import { TaskStatus } from "types/constants";
import { filesize } from "utils/filesize";
import { validateExportTasks } from "./ButtonDatasetExports.helpers";
import { formatDate } from "utils/formatDate";

interface ButtonDatasetExportsTableProps {
  items: ReturnType<typeof validateExportTasks>;
  onPageChange: (page: number) => void;
  pageIndex: number;
  pageSize: number;
  totalItemCount: number;
}

/**
 * Component rendering the content of the exports popover when at least one
 * export task exists
 */
export const ButtonDatasetExportsTable = ({
  items,
  onPageChange,
  pageIndex,
  pageSize,
  totalItemCount,
}: ButtonDatasetExportsTableProps) => {
  const [expandedItemId, setExpandedItemId] = useState<string>();
  const { openFlyout } = useDatasetLayoutContext();

  const toggleRow = (taskId: string) => {
    const item = items.find(({ task }) => task.id === taskId);

    if (expandedItemId === item?.task.id) {
      setExpandedItemId(undefined);
    } else {
      setExpandedItemId(item?.task.id);
    }
  };

  const itemIdToExpandedRowMap = (() => {
    const item = items.find(({ task }) => task.id === expandedItemId);

    if (item === undefined) {
      return;
    }

    const columns: EuiTableComputedColumnType<(typeof item.files)[number]>[] = [
      {
        name: "Recording Session ID",
        render: (file) => (
          <>
            {file.recordings.map((recording) => (
              <RecordingIdentifierBadge
                key={recording.id}
                recordingId={recording.id}
              />
            ))}
          </>
        ),
      },
      {
        name: "File",
        render: (file) => {
          const handleClick = () => {
            openFlyout({
              type: "fileInfo",
              props: { drsFile: file },
            });
          };

          return (
            <div style={{ display: "contents" }}>
              <FileBadge drsFile={file} onClick={handleClick} />
            </div>
          );
        },
      },
      {
        name: "Size",
        render: (file) => filesize(file.size),
      },
    ];

    return {
      [item.task.id]: (
        <EuiBasicTable
          columns={columns}
          items={item.files}
          style={{
            backgroundColor: "transparent",
            width: "100%",
            maxHeight: 300,
            overflow: "auto",
          }}
        />
      ),
    };
  })();

  const columns: EuiTableComputedColumnType<(typeof items)[number]>[] = [
    {
      align: "right",
      width: "40px",
      isExpander: true,
      name: (
        <EuiScreenReaderOnly>
          <span>Expand rows</span>
        </EuiScreenReaderOnly>
      ),
      render: ({ task }) => {
        return (
          <EuiButtonIcon
            iconType={expandedItemId === task.id ? "arrowDown" : "arrowRight"}
            onClick={() => toggleRow(task.id)}
          />
        );
      },
    },
    {
      name: "Date started",
      render: ({ task }) => formatDate(task.dateCreated),
      width: "200px",
    },
    {
      name: "User",
      render: ({ task }) => <UserAvatar userId={task.userId} />,
    },
    {
      name: "Number of files",
      render: ({ files }) => files.length,
    },
    {
      name: "Size",
      render: ({ files }) => {
        const totalSize = files
          .map((file) => BigInt(file.size ?? 0))
          .reduce((sum, fileSize) => sum + fileSize, BigInt(0));
        return filesize(totalSize);
      },
    },
    {
      name: "Status",
      render: ({ task }) => <TaskStatusBadge taskStatus={task.status} />,
    },
    {
      name: "Log",
      render: ({ task }) => (
        <ButtonViewTaskLogs taskId={task.id} taskStatus={task.status} />
      ),
    },
    {
      name: "Destination",
      render: ({ task, dandisetId, server }) => {
        const dandisetUrl = getDandisetUrl(dandisetId, server);
        return (
          task.status === TaskStatus.COMPLETE && (
            <EuiLink href={dandisetUrl} target="_blank">
              Dandiset
            </EuiLink>
          )
        );
      },
    },
  ];

  return (
    <>
      <EuiBasicTable<(typeof items)[number]>
        columns={columns}
        items={items}
        onChange={({
          page,
        }: CriteriaWithPagination<(typeof items)[number]>) => {
          onPageChange(page.index);
        }}
        pagination={{
          pageIndex,
          pageSize,
          totalItemCount,
          showPerPageOptions: false,
        }}
        isExpandable
        itemId={({ task }) => task.id}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      />
    </>
  );
};
